import { getToken } from '@/utils/auth';
import { data, redirect, useNavigation } from 'react-router';
import { useEffect } from 'react';
import { updateUIState } from '@/utils/cookies.server';

// Keep track of pending requests
let pendingRequests = 0;

export async function action({ request }: { request: Request }) {
  pendingRequests++;

  try {
    const formData = await request.formData();
    const { path, httpMethod, intent, filters, ...body } = Object.fromEntries(
      formData.entries()
    );

    if (intent === 'updateUIState' && filters) {
      const parsedFilters = JSON.parse(filters as string);
      const headers = new Headers();
      headers.append(
        'Set-Cookie',
        await updateUIState(request, {
          filters: parsedFilters,
        })
      );

      pendingRequests--;
      return data({ success: true }, { headers });
    }

    console.log('posting body', Object.fromEntries(formData.entries()));

    const endpoint = [process.env.VITE_API_URL, path].join('/');
    const resp = await fetch(endpoint, {
      method: httpMethod || 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken(request)}`,
      },
      body: JSON.stringify(body || {}),
    });

    const status = resp.status;

    if (status === 400) {
      console.error('Bad request', resp);
      pendingRequests--;
      return data(
        { error: 'Bad request. Please check your inputs and try again.' },
        { status: 400 }
      );
    }

    if (status === 422) {
      console.error('Unprocessable entity', resp);
      pendingRequests--;
      const body = await resp.json();
      const error = body.error;
      return data({ error }, { status: 422 });
    }

    if (status === 500) {
      console.error('Server error', resp);
      let errorMessage = 'Internal Server Error. Please try again later.';
      try {
        const body = await resp.json();
        errorMessage = body.error;
      } catch (e) {
        console.error('Error parsing server error', e);
      }
      pendingRequests--;
      return data({ error: errorMessage }, { status: 500 });
    }

    const jsonResponse = await resp.json();

    if (jsonResponse.downloadUrl) {
      pendingRequests--;
      return redirect(jsonResponse.downloadUrl);
    }

    if (jsonResponse.redirect_path) {
      pendingRequests--;
      return redirect(jsonResponse.redirect_path);
    }

    pendingRequests--;
    return data({
      ...jsonResponse,
      success: 'Success',
      intent: intent,
    });
  } catch (error) {
    pendingRequests--;
    throw error;
  }
}

// Add a client component to handle the beforeunload event
export function UpdateRouteWatcher() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (pendingRequests > 0 || isSubmitting) {
        // Cancel the event
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';

        // For older browsers
        const message =
          'Changes you made may not be saved. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    // Add both beforeunload and unload event listeners
    window.addEventListener('beforeunload', handleBeforeUnload, {
      capture: true,
    });
    window.addEventListener('unload', handleBeforeUnload, { capture: true });

    // Cleanup
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      });
      window.removeEventListener('unload', handleBeforeUnload, {
        capture: true,
      });
    };
  }, [isSubmitting]);

  // Log when the component mounts to verify it's being used
  useEffect(() => {
    console.log('UpdateRouteWatcher mounted');
  }, []);

  return null;
}

// Export the component so it can be used in the root route
