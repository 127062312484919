import { HOME_PATH } from '@/lib/utils';
import { get } from '@/utils/api';
import { currentUser } from '@/utils/auth';
import { redirect } from 'react-router';

export async function loader({ request }) {
  const user = await currentUser(request);
  console.log('index loader', { user });

  if (!user) {
    console.log('no user');
    return redirect('/signin');
  } else {
    console.log('there is a user');
  }

  if (user.admin) {
    return redirect(HOME_PATH);
  }

  const meResponse = await get({
    request,
    path: 'me',
  });

  const { user: me } = await meResponse.json();

  if (me.onboardingStatus === 'in_progress') {
    return redirect('/onboarding');
  }

  if (me.onboardingStatus === 'submitted_intake') {
    return redirect('/onboarding');
  }

  return redirect('/dashboard');
}
export default function Dashboard() {}
